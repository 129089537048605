import './modals/modals';

export class ModalCore {
    MODAL_CLASSES = {};

    constructor() {
        app.MODAL_CORE = this;

        window.addEventListener('RegisterModal', (e) => {
            if(!e.detail) {
                console.warn('Invalid modal register event call.', e);
                return;
            }

            if(!e.detail.id || !e.detail.instance) {
                console.warn('Invalid modal detail data provided to register event.', e.detail);
                return;
            }

            this.MODAL_CLASSES[e.detail.id] = e.detail.instance;
            window.dispatchEvent(new Event('ModalClassRegistered'));
        });

        window.addEventListener('RebindModalButtons', (e) => {
            this.setupButtonEvents();
        });

        if(!app.OPTIONS.list_ajax) {
            this.setupButtonEvents();
        }

        window.dispatchEvent(new Event('ModalCoreSetup'));
    }

    setupButtonEvents() {
        $('.modal-open').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const el = $(e.currentTarget);
            const data = el.data();
            if(!data.modal) {
                console.warn('Missing attr data-modal to open a modal on this element', el);
                return;
            }

            this.showModal(data.modal, el, data);
        });
    }

    showModal(modalId, $btn, additionalData) {
        if(!this.MODAL_CLASSES[modalId]) {
            console.warn(`Modal not found with id ${modalId}.`);
            return;
        }

        this.MODAL_CLASSES[modalId].show($btn, additionalData);
    }

    closeModal(modalId, message) {
        if(!this.MODAL_CLASSES[modalId]) {
            console.warn(`Modal not found with id ${modalId}`);
            return;
        }

        this.MODAL_CLASSES[modalId].hide(message);
    }
}

$(() => {
    new ModalCore();
});
